import {
  actionChannel,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take
} from 'redux-saga/effects'
import { CHECK_TOKEN_EXPIRATION, CLEAR_SELECTED_BRANCH } from 'auth/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import { getFormSelector } from 'ddiForm/utils'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { SET_FIELD, REGISTER_FIELD } from 'ddiForm/constants'
import { fromJS, getIn } from 'utils'
import { isMobileSelector } from './selectors'
import { TOGGLE_MOBILE, TRY_OPEN_SCREEN } from './constants'
import { openScreen, openScreenAttempt } from './actions'

let tasks

const toggleMobileListener = function* toggleMobileListener() {
  while (true) {
    yield take([TOGGLE_MOBILE, CHECK_TOKEN_EXPIRATION])

    if (tasks) {
      yield cancel(tasks)
    }
  }
}

export function* clearSelectedBranchListener() {
  const channel = yield actionChannel(CLEAR_SELECTED_BRANCH)
  while (true) {
    yield take(channel)

    yield put(openScreenAttempt({ link: 'app' }))
  }
}

// export function* tryOpenScreenProcess(action) {
//   let forms = yield select(state => state.get('ddiForm'))
//   forms = forms?.toJS() || {}
//   let editedForm
//   let openForm
//   for (const form of Reflect.ownKeys(forms)) {
//     console.log(form)
//     openForm = forms[form]
//     if (openForm.isEditing) {
//       editedForm = form
//       break
//     }
//   }
//   debugger
//   if (editedForm || openForm) {
//     let cancelled
//     const formState = yield select(getFormSelector(editedForm || openForm))
//     if (formState && formState.toJS) {
//       const isEditing = formState.get('isEditing')
//       const noCancelEditProcess = getIn(formState, 'noCancelEditProcess')
//       if (noCancelEditProcess) {
//         const saga = getIn(formState, 'sagas.beforeClose')
//         if (saga) {
//           try {
//             yield call(saga, editedForm || openForm)
//             yield put(openScreenAttempt(action.payload))
//           } catch (e) {
//             console.log(e)
//           }
//         }
//       } else if (isEditing) {
//         yield put(masterActions.cancelEditAsync.try(editedForm))
//         cancelled = yield take([
//           MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS,
//           MASTER_CONSTANTS.CANCEL_EDIT.FAILURE
//         ])
//         if (cancelled.type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS) {
//           yield put(openScreenAttempt(action.payload))
//         }
//       } else {
//         yield put(openScreenAttempt(action.payload))
//       }
//     }
//   } else {
//     yield put(openScreenAttempt(action.payload))
//   }
// }

export function* tryOpenScreenProcess(action) {
  let forms = yield select(state => state.get('ddiForm'))
  forms = forms?.toJS() || {}
  // let editedForm
  let openForm
  // debugger
  for (const form of Reflect.ownKeys(forms)) {
    console.log(form)
    // should only be one form open at a time..
    if (form !== 'mobileGlobal') {
      openForm = { form, formState: forms[form] }
    }
    // if (openForm.isEditing) {
    //   editedForm = form
    //   break
    // }
  }

  const openIt = function* openIt() {
    yield put(openScreenAttempt(action.payload))
  }
  if (openForm) {
    let cancelled
    const { formState, form } = openForm
    if (formState) {
      const isEditing = getIn(formState, 'isEditing')
      const noCancelEditProcess = getIn(formState, 'noCancelEditProcess')
      const beforeCloseSaga = getIn(formState, 'sagas.beforeClose')
      // debugger
      if (noCancelEditProcess) {
        if (beforeCloseSaga) {
          try {
            yield call(beforeCloseSaga, form, openIt)
            // yield openIt()
          } catch (e) {
            console.log(e)
          }
        }
      } else if (isEditing) {
        yield put(masterActions.cancelEditAsync.try(form))
        cancelled = yield take([
          MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS,
          MASTER_CONSTANTS.CANCEL_EDIT.FAILURE
        ])

        if (cancelled.type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS) {
          // debugger
          yield openIt()
        }
      } else if (!isEditing && !noCancelEditProcess && beforeCloseSaga) {
        /* 
          basically we have to call cancelEdit with a clear action 
          anytime we leave the Sales Order screen, for cases where user
          has pulled up a record, but not edited it. This is pretty important
          due to line item cache API changes -- SVE 5/10/2021
        */
        try {
          yield call(beforeCloseSaga, form, openIt)
        } catch (e) {
          console.log(e)
        }
      } else {
        // debugger
        yield openIt()
      }
    }
  } else {
    // debugger
    // no open forms...
    yield openIt()
  }
}
export function* tryOpenScreenListener() {
  const channel = yield actionChannel(TRY_OPEN_SCREEN)
  while (true) {
    const action = yield take(channel)
    console.log(action)
    yield fork(tryOpenScreenProcess, action)
  }
}

export function* checkForMobileGlobalCustomerProcess(
  form,
  customerFieldKey = 'customerId',
  cb = null
) {
  // debugger
  const mobileGlobal = yield select(state =>
    getIn(state, 'ddiForm.mobileGlobal')
  ) || fromJS({})
  const globalCustomer =
    getIn(mobileGlobal, 'customer') || fromJS({ id: null, description: null })
  // debugger
  const customerId = globalCustomer.get('id') || null
  const customerDescription = globalCustomer.get('description') || null

  if (customerId) {
    const val = yield select(state =>
      getIn(state, `ddiForm.${form}.fields.${customerFieldKey}.value`)
    )
    if (val !== '') {
      yield take(act => {
        return (
          act.type === REGISTER_FIELD &&
          act?.payload?.propertyName === customerFieldKey
        )
      })
    }
    yield put({
      type: SET_FIELD,
      meta: { form, customer: customerId, description: customerDescription },
      payload: {
        propertyName: customerFieldKey,
        value: customerId,
        results: { description: customerDescription }
      }
    })
    if (cb && typeof cb === 'function') {
      yield fork(cb, form, customerId, customerDescription, customerFieldKey)
    }
  }
}

const mobileSagas = function* mobileSagas() {
  yield fork(toggleMobileListener)
  yield fork(clearSelectedBranchListener)
  yield fork(tryOpenScreenListener)
}
export default mobileSagas
