import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Paper
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import './styles/ddi-card-wrapper.scss'

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 30,
    padding: '0 14px',
    '&$expanded': {
      minHeight: 30
    }
  },
  content: {
    margin: '5px 0',
    '&$expanded': {
      margin: '5px 0'
    }
  },
  expandIcon: {
    padding: 0
  },
  expanded: {}
})(MuiExpansionPanelSummary)

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 10
  }
}))(MuiExpansionPanelDetails)

export const DDICardWrapper = ({
  children,
  className,
  contentClassName,
  contentStyle,
  defaultExpanded = false,
  expansionPanelStyle,
  expansionDetailsStyle,
  expandedDetailsStyleName,
  expandedSummaryStyleName,
  panelName = 'panel',
  onExpandedCb = noop,
  headlineClassName,
  headlineStyle,
  isExpandable,
  secondaryTitle,
  secondaryTitleStyle,
  style,
  title
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded)

  const handleChange = panel => (event, isExpanded) => {
    const expansionPanelState = isExpanded ? panel : false
    setExpanded(expansionPanelState)

    if (onExpandedCb && typeof onExpandedCb === 'function') {
      onExpandedCb(expansionPanelState)
    }
  }

  return (
    <Paper
      className={className}
      style={style}
      // ref={el => (this.formFieldsWrapper = el)}
    >
      {isExpandable ? (
        <ExpansionPanel
          square
          defaultExpanded={defaultExpanded}
          style={style}
          expanded={expanded === panelName}
          onChange={handleChange(panelName)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            style={{
              minHeight: '35px',
              ...expansionPanelStyle
            }}
          >
            <div className={expandedSummaryStyleName}>{title}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={expansionDetailsStyle}>
            <div className={expandedDetailsStyleName}>{children}</div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ) : (
        <>
          {title ? (
            <div
              className="ddi-card-heading"
              className={headlineClassName}
              style={headlineStyle}
            >
              <h2>
                {title}
                {secondaryTitle ? (
                  <span
                    style={{
                      display: 'inline-block',
                      marginLeft: 10,
                      ...secondaryTitleStyle
                    }}
                  >
                    {secondaryTitle}
                  </span>
                ) : null}
              </h2>
            </div>
          ) : null}
          <div
            className="ddi-card-content clear"
            className={contentClassName}
            style={contentStyle}
          >
            {children}
          </div>
        </>
      )}
    </Paper>
  )
}

DDICardWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.object,
  defaultExpanded: PropTypes.bool,
  expansionPanelStyle: PropTypes.object,
  expansionDetailsStyle: PropTypes.object,
  expandedDetailsStyleName: PropTypes.string,
  expandedSummaryStyleName: PropTypes.string,
  headlineClassName: PropTypes.string,
  headlineStyle: PropTypes.object,
  isExpandable: PropTypes.bool,
  secondaryTitle: PropTypes.string,
  secondaryTitleStyle: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.string
}

DDICardWrapper.defaultProps = {
  className: 'ddi-card',
  contentClassName: 'ddi-card-content clear',
  contentStyle: {},
  defaultExpanded: true,
  expansionPanelStyle: {},
  expansionDetailsStyle: {},
  expandedDetailsStyleName: 'ddi-expanded-details',
  expandedSummaryStyleName: 'ddi-expanded-summary-title',
  headlineClassName: 'ddi-card-heading',
  headlineStyle: {},
  isExpandable: false,
  secondaryTitle: '',
  secondaryTitleStyle: { fontSize: 11, fontStyle: 'italic' },
  style: {},
  title: undefined
}

export default DDICardWrapper
