import React, { memo } from 'react'
import { MenuItem, Select, TextField } from '@material-ui/core'
import DDISendInfoSearch from 'ddiForm/wrapped/DDISendInfoSearch'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Content = ({
  body,
  style,
  styles,
  Wrapper,
  onModelChange,
  onSelectChange,
  sendType,
  onChange,
  fromCompany,
  fromName,
  sendTo,
  data = {},
  sendInfoSearch,
  subject,
  mobileState
}) => (
  <div style={style}>
    <div style={styles.flexContainer}>
      <Wrapper title="Send Type">
        <div style={styles.flexContainer}>
          <div style={styles.left}>Fax / Email:</div>
          <div style={styles.right}>
            <Select onChange={onSelectChange} value={sendType}>
              <MenuItem value="Email">Email</MenuItem>
              <MenuItem value="Fax">Fax</MenuItem>
            </Select>
          </div>
        </div>
      </Wrapper>
    </div>
    <div style={styles.flexContainer}>
      <Wrapper title="Send From">
        <div style={styles.flexContainer}>
          <div style={{ ...styles.half, alignItems: 'baseline' }}>
            <div style={{ ...styles.left, minWidth: 65 }}>Company:</div>
            <div style={styles.right}>
              <TextField
                onChange={onChange('fromCompany')}
                value={fromCompany}
                style={{ minWidth: 150 }}
              />
            </div>
          </div>
          <div style={{ ...styles.half, alignItems: 'baseline' }}>
            <div style={{ ...styles.left, minWidth: 65 }}>Name:</div>
            <div style={styles.right}>
              <TextField
                onChange={onChange('fromName')}
                value={fromName}
                style={{ minWidth: 150 }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
    <div style={styles.flexContainer}>
      <Wrapper title="Send To">
        <div style={styles.flexContainer}>
          <DDISendInfoSearch
            propertyName="sendInfoSearch"
            sendType={sendType}
            initialValue={sendTo}
            meta={data.meta}
            fullWidth
            preventAutoDisable
            searchRef={c => (sendInfoSearch = c)}
            wrapperStyle={{
              position: 'static',
              clear: 'none',
              maxWidth: '100%',
              width: '100%'
            }}
            mobileState={
              mobileState && mobileState?.toJS
                ? mobileState.toJS()
                : { isMobile: false, isTablet: false }
            }
          />
        </div>
      </Wrapper>
    </div>
    <div style={styles.flexContainer}>
      <Wrapper title="Content">
        <div className="send-document-content-editor-wrapper">
          <div style={{ ...styles.flexContainer, alignItems: 'baseline' }}>
            <div style={styles.left}>Subject:</div>
            <div style={styles.right}>
              <TextField onChange={onChange('subject')} value={subject} />
            </div>
          </div>
          <div style={{ ...styles.flexContainer, marginTop: 10 }}>
            <div style={styles.left}>
              {sendType === 'Email' ? 'Body:' : 'Comments:'}
            </div>
            <div
              style={{
                ...styles.right,
                flex: 1,
                flexGrow: 1,
                flexWrap: 'wrap',
                padding: '0px 10px 100px 10px'
              }}
            >
              {sendType === 'Email' ? (
                // <Froala
                //   model={body}
                //   onModelChange={onModelChange}
                //   height={275}
                //   placeholderText=""
                // />
                <div style={{ minHeight: 130 }}>
                  <ReactQuill value={body} onChange={onModelChange} />
                </div>
              ) : (
                <TextField
                  value={body}
                  onChange={onChange('body')}
                  multiline
                  rows={20}
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  </div>
)

Content.defaultProps = {
  style: {
    padding: 5,
    background: 'white',
    display: 'flex',
    flexDirection: 'column'
  }
}

export default memo(Content)
