import { getIn, setIn, makeRowDataUnique } from 'utils'
import { Map, fromJS, List } from 'immutable'
import shortid from 'shortid'
import * as DDICONSTANTS from 'ddiForm/constants'
// import * as EDITABLE_GRID_CONSTANS from 'components/EditableGrid/constants'
import * as CONSTANTS from './constants'

const validateGridRowImmutable = (rowsToTest, data) =>
  rowsToTest.reduce((a, n) => {
    if (Map.isMap(data) && !data.get(n)) {
      a += 1
    }
    return a
  }, 0)

const updateEditedFields = (state, propertyName) => {
  let editedFields = getIn(state, 'editedFields')
  if (editedFields && !propertyName.match(/selectionCriteria/gi)) {
    editedFields = editedFields.add(propertyName)
    state = setIn(state, 'editedFields', editedFields)
  }

  return state
}

const editableGridBehaviors = {
  [CONSTANTS.ON_PRIMARY_GRID_DATA_VALIDATED]: (
    state,
    { payload: { rowIndex, newData, propertyName } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    // const valueKey = `fields.${propertyName}.value`
    const isPendingKey = `fields.${propertyName}.isPending`
    let rowData = getIn(result, rowDataKey)

    const { description, recordName, moreInfo } = newData
    rowData = rowData.update(rowIndex, data => data.set('dataId', recordName))

    if (description) {
      rowData = rowData.update(rowIndex, data =>
        data.set('description', description)
      )
    }

    if (moreInfo && typeof moreInfo === 'object' && moreInfo !== null) {
      for (const prop in moreInfo) {
        rowData = rowData.update(rowIndex, data =>
          data.set(prop, moreInfo[prop])
        )
      }
    }

    result = updateEditedFields(result, propertyName)

    result = setIn(result, rowDataKey, rowData)
    result = setIn(result, `values.${propertyName}`, rowData)
    // result = setIn(result, valueKey, rowData)

    if (rowData.size === rowIndex + 1 && recordName) {
      result = setIn(result, isPendingKey, false)
    }

    result = setIn(result, `fields.${propertyName}.grid`, true)

    return result
  },
  [CONSTANTS.DELETE_GRID_ROW]: (
    state,
    { payload: { propertyName, rowId } }
  ) => {
    let result = state
    if (propertyName) {
      const rowDataKey = `fields.${propertyName}.rowData`
      // const valueKey = `fields.${propertyName}.value`
      let rowData = getIn(result, rowDataKey)
      const rowIndex = rowData.findIndex(x => x.get('rowId') === rowId)

      const requiredCols = getIn(result, `fields.${propertyName}.requiredCols`)
        ? getIn(result, `fields.${propertyName}.requiredCols`).toJS()
        : []

      // const rowToDelete = rowData.get(rowIndex)
      rowData = rowData.delete(rowIndex)
      // const missingDataCount = validateGridRowImmutable(
      //   requiredCols,
      //   rowToDelete
      // )

      // if (missingDataCount === 0) {
      //   rowData = rowData.delete(rowIndex)
      // }

      result = updateEditedFields(result, propertyName)

      result = setIn(result, rowDataKey, rowData)
      result = setIn(result, `values.${propertyName}`, rowData)
      // result = setIn(result, valueKey, rowData)

      /*
        now make sure if the last row in the set is incomplete
        we set the isPending flag is properly
      */
      if (
        rowData &&
        rowData.size &&
        requiredCols &&
        requiredCols.length &&
        List.isList(rowData)
      ) {
        const missingDataCountTotal = rowData.reduce((acc, next) => {
          const missingDataCount = validateGridRowImmutable(requiredCols, next)
          if (missingDataCount > 0) {
            acc += 1
          }
          return acc
        }, 0)

        const isPending = !(missingDataCountTotal === 0)
        result = setIn(result, `fields.${propertyName}.isPending`, isPending)
      } else {
        result = setIn(result, `fields.${propertyName}.isPending`, false)
      }
    }

    return result
  },
  [CONSTANTS.UPDATE_GRID_CELL_DATA]: (
    state,
    {
      payload: {
        rowId,
        propertyName,
        // requiredCols,
        field,
        value,
        generateDataId,
        lastCell,
        requiresAdditionalValidation
      },
      meta
    }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    // const valueKey = `fields.${propertyName}.value`
    const isPendingKey = `fields.${propertyName}.isPending`
    const emptyRow = getIn(result, `fields.${propertyName}.emptyRow`)

    const requiredCols = getIn(result, `fields.${propertyName}.requiredCols`)
      ? getIn(result, `fields.${propertyName}.requiredCols`).toJS()
      : []

    let rowData = getIn(result, rowDataKey)
    const rowIndex = rowData.findIndex(x => x.get('rowId') === rowId)
    rowData = rowData.update(rowIndex, data => data.set(field, value))

    if (
      rowData &&
      rowData.size &&
      requiredCols &&
      requiredCols.length &&
      List.isList(rowData)
    ) {
      /* OK we pretty much just need to validate every row from now on */
      const missingDataCountTotal = rowData.reduce((acc, next) => {
        const missingDataCount = validateGridRowImmutable(requiredCols, next)
        if (missingDataCount > 0) {
          acc += 1
        }
        return acc
      }, 0)

      // result = setIn(result, rowDataKey, newRowData)

      const isPending = !(missingDataCountTotal === 0)
      if (
        rowIndex + 1 === rowData.size &&
        lastCell &&
        missingDataCountTotal === 0 &&
        !requiresAdditionalValidation
      ) {
        rowData = rowData.push(emptyRow)
        result = setIn(result, isPendingKey, true)
      } else {
        result = setIn(result, isPendingKey, isPending)
      }
    }

    result = updateEditedFields(result, propertyName)
    result = setIn(result, rowDataKey, rowData)
    result = setIn(result, `fields.${propertyName}.grid`, true)
    result = setIn(result, `values.${propertyName}`, rowData)
    // result = setIn(result, valueKey, rowData)
    return result
  },
  [CONSTANTS.INITIALIZE_EDITABLE_GRID]: (
    state,
    { payload: { requiredCols, propertyName } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`

    let rowData = getIn(result, rowDataKey)
    rowData = rowData && rowData.toJS ? rowData.toJS() : []

    /* set up rowId for any & all editable grids */
    rowData = makeRowDataUnique(rowData, 'rowId')
    result = setIn(result, rowDataKey, fromJS(rowData))
    result = setIn(result, `values.${propertyName}`, fromJS(rowData))

    result = setIn(
      result,
      `fields.${propertyName}.requiredCols`,
      fromJS(requiredCols)
    )
    // let rowData = getIn(result, rowDataKey)
    return result
  },
  [CONSTANTS.SET_BLANK_ROW_ID]: (state, { payload: { propertyName } }) => {
    // debugger
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    let rowData = getIn(result, rowDataKey)
    const rowIndex = rowData && rowData.size ? rowData.size - 1 : 0
    const row = rowData.get(rowIndex)

    // console.log('row', row.toJS())
    // console.log('rowId', row.get('rowId'))
    // debugger
    if (rowData && rowData.size && !rowData.get(rowIndex).get('rowId')) {
      rowData = rowData.update(rowIndex, data =>
        data.set('rowId', shortid.generate())
      )

      result = setIn(result, rowDataKey, rowData)
      result = setIn(result, `values.${propertyName}`, rowData)
    }

    result = setIn(result, `fields.${propertyName}.isPending`, true)

    return result
  },
  [CONSTANTS.SET_REQUIRED_GRID_COLS]: (
    state,
    { payload: { requiredCols, propertyName } }
  ) => {
    let result = state
    // const fieldsKey = `fields.${propertyName}`
    // debugger
    result = setIn(
      result,
      `fields.${propertyName}.requiredCols`,
      fromJS(requiredCols)
    )
    // let rowData = getIn(result, rowDataKey)
    return result
  },
  [CONSTANTS.SET_FOCUSED_CELL]: (
    state,
    { payload: { propertyName, field, rowIndex } }
  ) => {
    let result = state
    if (propertyName && field) {
      result = setIn(
        result,
        `fields.${propertyName}.focusedCell`,
        fromJS({
          field,
          rowIndex
        })
      )
    }

    return result
  },
  [CONSTANTS.CLEAR_GRID_ROW]: (
    state,
    { payload: { propertyName, rowIndex, skipFields = [], defaultResets = {} } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    let rowData = getIn(result, rowDataKey)
    rowData = rowData && rowData.toJS ? rowData.toJS() : []

    if (rowData && rowData.length && rowData[rowIndex]) {
      rowData[rowIndex] = Object.keys(rowData[rowIndex]).reduce((acc, next) => {
        if (
          skipFields &&
          Array.isArray(skipFields) &&
          skipFields.length &&
          skipFields.includes(next)
        ) {
          acc[next] = rowData[rowIndex][next]
        } else {
          acc[next] =
            (defaultResets && defaultResets[next]) ||
            (defaultResets && defaultResets[next] === false)
              ? defaultResets[next]
              : ''
        }
        return acc
      }, {})
    }

    result = setIn(result, rowDataKey, fromJS(rowData))
    result = setIn(result, `values.${propertyName}`, fromJS(rowData))
    result = updateEditedFields(result, propertyName)
    return result
  },
  [CONSTANTS.RESET_GRID_INDEX_SEARCH]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state

    result = setIn(result, `fields.${propertyName}.indexSearchField.value`, '')
    result = setIn(
      result,
      `fields.${propertyName}.indexSearchField.isSet`,
      false
    )

    result = setIn(
      result,
      `fields.${propertyName}.indexSearchField.grid`,
      fromJS({})
    )

    return result
  },
  [CONSTANTS.VALIDATE_GRID_DATA]: (state, { payload: { propertyName } }) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    const requiredCols = getIn(result, `fields.${propertyName}.requiredCols`)
      ? getIn(result, `fields.${propertyName}.requiredCols`).toJS()
      : []
    const rowData = getIn(result, rowDataKey)

    if (
      rowData &&
      rowData.size &&
      requiredCols &&
      requiredCols.length &&
      List.isList(rowData)
    ) {
      /* OK we pretty much just need to validate every row from now on */
      const newRowData = rowData.reduce((acc, next) => {
        const missingDataCount = validateGridRowImmutable(requiredCols, next)
        if (missingDataCount === 0) {
          acc = acc.push(next)
        }
        return acc
      }, List())

      result = setIn(result, rowDataKey, newRowData)
      result = setIn(result, `values.${propertyName}`, newRowData)
      result = setIn(result, `fields.${propertyName}.isPending`, false)
    }

    if (getIn(result, `fields.${propertyName}`)) {
      /* ensure its flagged as a grid field */
      result = setIn(result, `fields.${propertyName}.grid`, true)
    }

    if (getIn(result, `fields.${propertyName}`) && !rowData.size) {
      result = setIn(result, `fields.${propertyName}.isPending`, false)
    }

    return result
  },
  [CONSTANTS.INSERT_GRID_ROW]: (
    state,
    { payload: { propertyName, rowIndex } }
  ) => {
    let result = state
    const grid = getIn(result, `fields.${propertyName}`)
    const emptyRow = getIn(grid, 'emptyRow')
    let rowData = getIn(grid, 'rowData')
    const rowDataKey = `fields.${propertyName}.rowData`

    if (grid && emptyRow && List.isList(rowData)) {
      rowData = rowData.insert(rowIndex, fromJS(emptyRow))
      result = setIn(result, rowDataKey, rowData)
      result = setIn(result, `values.${propertyName}`, rowData)
      result = setIn(result, `fields.${propertyName}.isPending`, true)
      result = setIn(result, `fields.${propertyName}.grid`, true)
    }

    return result
  },
  [CONSTANTS.SAVED_SORTED_ROW_DATA]: (
    state,
    { payload: { propertyName, rowData } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    result = setIn(result, rowDataKey, fromJS(rowData))
    result = setIn(result, `fields.${propertyName}.grid`, true)

    return result
  },
  [CONSTANTS.ENSURE_ROW_IDS_SET]: (state, { payload: { propertyName } }) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`

    let rowData = getIn(result, rowDataKey)
    // rowData = rowData && rowData.toJS ? rowData.toJS() : []
    if (List.isList(rowData)) {
      rowData = rowData.reduce((acc, next) => {
        if (!next.get('rowId')) {
          acc = acc.push(next.set('rowId', shortid.generate()))
        } else {
          acc = acc.push(next)
        }

        return acc
      }, List())
    }

    /* set up rowId for any & all editable grids */
    // rowData = makeRowDataUnique(rowData, 'rowId')
    result = setIn(result, rowDataKey, fromJS(rowData))
    result = setIn(result, `values.${propertyName}`, fromJS(rowData))

    // let rowData = getIn(result, rowDataKey)
    return result
  }
}

export default editableGridBehaviors
