import { Set } from 'immutable'
import { isValidElementType } from 'react-is'
import { debounce, isObjectLike } from 'lodash'
import { compose } from 'redux'
import {
  deepEqual,
  isGrid,
  is,
  deleteIn,
  getIn,
  noop,
  fromJS,
  setIn,
  empty,
  emptyList,
  toLower,
  toCamelCase,
  trimNonWord
} from 'utils'
import { fork } from 'redux-saga/effects'
import { BASE_INFORM_URL } from 'services/constants'
import { onDoubleClick as onDoubleClickAction } from './MasterScreen/actions'
// export const createTitle = (title, image) => {
//   const img = `<span><img height="14" width="14" style="margin-top:-4px" src="${
//     BASE_INFORM_URL
//   }/resources/white/${
//     image ? `${image}` : 'default-inform-icon-16.png'
//   }" alt="${image || 'default'}" />&nbsp;&nbsp;${title}</span>`
//   return img
// }

export const createTitle = (title, image) => {
  const img = `<span><img height="14" width="14" style="margin-top:-4px" src="${BASE_INFORM_URL}/resources/white/${
    image ? `${image}` : 'default-inform-icon-16.png'
  }" alt="${image ||
    'default'}" /><span style="margin-right:5px"></span>${title}</span>`
  return img
}

export const getSelectedTabsFromState = formState => {
  const selectedTab = getIn(formState, 'masterOptions.selectedPrimaryTab')
  const selectedSecond = getIn(formState, 'masterOptions.selectedSecondaryTab')
  const ret = [selectedTab]
  if (selectedSecond) {
    ret.push(selectedSecond)
  }
  // ret = ret.map(trimNonWord)
  return ret
}

// export const getSelectedTabsFromState = formState => {
//   const masterOptions = getIn(formState, 'masterOptions')
//   console.log(masterOptions.toJS())
//   const selectedTab = getIn(formState, 'masterOptions.selectedPrimaryTab')
//   const selectedSecond = getIn(formState, 'masterOptions.selectedSecondaryTab')
//   // const ret = [selectedTab]
//   const ret = []
//   debugger
//   if (selectedSecond) {
//     ret.push(selectedSecond)
//   } else {
//     const tab = getIn(masterOptions, `tabComponents.${selectedTab}`)
//     ret.push(getIn(tab, 'access'))
//   }
//   // ret = ret.map(trimNonWord)
//   return ret
// }

// export const getGroupNamesFromState = formState => {
//   const masterOptions = getIn(formState, 'masterOptions')
//   console.log(masterOptions.toJS())
//   const selectedTab = getIn(formState, 'masterOptions.selectedPrimaryTab')
//   const selectedSecond = getIn(formState, 'masterOptions.selectedSecondaryTab')
//   // const ret = [selectedTab]
//   const ret = []
//   debugger
//   if (selectedSecond) {
//     ret.push(selectedSecond)
//   } else {
//     const tab = getIn(masterOptions, `tabComponents.${selectedTab}`)
//     ret.push(getIn(tab, 'access'))
//   }
//   // ret = ret.map(trimNonWord)
//   return ret
// }

export const toggleFetch = (state, bool) => {
  let result = state

  result = setIn(
    result,
    'fetchingEntity',
    is.bool(bool) ? bool : !getIn(result, 'fetchingEntity')
  )
  return result
}
// export const mapResponse = (response, tabs) => {
//   if (response) {
//     debugger
//     const selectedTabData = response[toLower(tabs[0])]
//     delete response[toLower(tabs[0])]
//     if (selectedTabData) {
//       response = {
//         ...response,
//         ...selectedTabData
//       }
//     }
//   }
//   return response
// }

// export const mapResponse = (response, tabs, formState) => {
//   if (response) {
//     // debugger
//     tabs.forEach(tab => {
//       const t = toCamelCase(tab)
//       const selectedTabData = response[t]
//       delete response[t]
//       if (selectedTabData) {
//         response = {
//           ...response,
//           ...selectedTabData
//         }
//       }
//     })
//   }
//   response.groupNames = tabs
//   return response

// current fubar'd version
// export const mapResponse = (response, tabs = [], formState) => {
//   debugger
//   let newResponse = response ? { ...response } : null
//   let t
//   let selectedTabData
//   if (newResponse) {
//     // const tempResponse = Object.keys(newResponse).reduce((acc, next) => {
//     //   acc[next.toLowerCase()] = newResponse[next]
//     //   return acc
//     // }, {})
//     // const tabComponents = getIn(formState, 'masterOptions.tabComponents')
//     // const secondaryTab = getIn(tabComponents, `${tabs[0]}-${tabs[1]}`)
//     // if (secondaryTab && !getIn(secondaryTab, 'access')) {
//     //   tabs = tabs.slice(0, 1)
//     // }
//     if (tabs.length === 1) {
//       t = toCamelCase(tabs[0]).toLowerCase()
//       selectedTabData = newResponse[t]
//       // delete tempResponse[t]
//       if (isObjectLike(selectedTabData) && !Array.isArray(selectedTabData)) {
//         debugger
//         delete newResponse[t]
//         newResponse = {
//           ...newResponse,
//           ...selectedTabData
//         }
//       }
//     } else if (tabs.length === 2) {

//       t = toCamelCase(tabs[0])
//       const t1 = toCamelCase(tabs[1])
//       if (newResponse[t] && newResponse[t][t1]) {
//         if (Array.isArray(newResponse[t][t1])) {
//           selectedTabData = newResponse[t]
//         } else {
//           selectedTabData = newResponse[t][t1]
//         }
//       } else if (newResponse[t]) {
//         selectedTabData = newResponse[t]
//       } else if (newResponse[t1]) {
//         selectedTabData = newResponse[t1]
//       }
//       if (Object.keys(selectedTabData).length === 2 && selectedTabData.meta && selectedTabData.data) {
//         // a grid..
//         selectedTabData = {
//           [t1]: {...selectedTabData }
//         }
//         delete selectedTabData.meta
//         delete selectedTabData.data
//       }
//       debugger
//       if (isObjectLike(selectedTabData) && !Array.isArray(selectedTabData)) {
//         debugger
//         delete newResponse[t]
//         newResponse = {
//           ...selectedTabData,
//           ...newResponse
//         }
//       }
//     }
//   }
//   debugger
//   newResponse.groupNames = tabs
//   return newResponse
// }

// export const mapResponse = (response, tabs = [], formState) => {
//   debugger
//   let newResponse = response ? { ...response } : null
//   let t
//   let selectedTabData
//   if (newResponse) {
//     // const tempResponse = Object.keys(newResponse).reduce((acc, next) => {
//     //   acc[next.toLowerCase()] = newResponse[next]
//     //   return acc
//     // }, {})
//     // const tabComponents = getIn(formState, 'masterOptions.tabComponents')
//     // const secondaryTab = getIn(tabComponents, `${tabs[0]}-${tabs[1]}`)
//     // if (secondaryTab && !getIn(secondaryTab, 'access')) {
//     //   tabs = tabs.slice(0, 1)
//     // }
//     if (tabs.length === 1) {
//       t = toCamelCase(tabs[0]).toLowerCase()
//       selectedTabData = newResponse[t]
//       // delete tempResponse[t]
//       if (isObjectLike(selectedTabData) && !Array.isArray(selectedTabData)) {
//         debugger
//         delete newResponse[t]
//         newResponse = {
//           ...newResponse,
//           ...selectedTabData
//         }
//       }
//     } else if (tabs.length === 2) {
//       debugger
//       // const t = toCamelCase(tab)
//       t = toCamelCase(tabs[0])
//       // selectedTabData = newResponse[t][toCamelCase(tabs[1]).toLowerCase()] || newResponse[t]
//       try {
//         selectedTabData = newResponse[t][toCamelCase(tabs[1]).toLowerCase()] || newResponse[t] ||
//       } catch (e) {
//         selectedTabData = newResponse[t] || {}
//       }

//       debugger
//       if (isObjectLike(selectedTabData) && !Array.isArray(selectedTabData)) {
//         debugger
//         delete newResponse[t]
//         newResponse = {
//           ...newResponse,
//           ...selectedTabData
//         }
//       }
//     }
//     // tabs.forEach(tab => {
//     //   debugger
//     //   // const t = toCamelCase(tab)
//     //   const t = toCamelCase(tab).toLowerCase()
//     //   const selectedTabData = newResponse[t]
//     //   // delete tempResponse[t]
//     //   if (isObjectLike(selectedTabData) && !Array.isArray(selectedTabData)) {
//     //     debugger
//     //     delete newResponse[t]
//     //     newResponse = {
//     //       ...newResponse,
//     //       ...selectedTabData
//     //     }
//     //   }
//     // })
//   }
//   debugger
//   newResponse.groupNames = tabs
//   return newResponse
// }
// export const mapResponse = (response, tabs = [], formState) => {
//   debugger
//   let newResponse = {...response }
//   if (newResponse) {
//     // const tempResponse = Object.keys(newResponse).reduce((acc, next) => {
//     //   acc[next.toLowerCase()] = newResponse[next]
//     //   return acc
//     // }, {})
//     const tabComponents = getIn(formState, 'masterOptions.tabComponents')
//     const secondaryTab = getIn(tabComponents, `${tabs[0]}-${tabs[1]}`)
//     if (secondaryTab && !getIn(secondaryTab, 'access')) {
//       tabs = tabs.slice(0, 1)
//     }
//     tabs.forEach(tab => {
//       debugger
//       // const t = toCamelCase(tab)
//       const t = toCamelCase(tab).toLowerCase()
//       const selectedTabData = newResponse[t]
//       // delete tempResponse[t]
//       if (isObjectLike(selectedTabData) && !Array.isArray(selectedTabData)) {
//         debugger
//         delete newResponse[t]
//         newResponse = {
//           ...newResponse,
//           ...selectedTabData
//         }
//       }
//     })
//   }
//   debugger
//   newResponse.groupNames = tabs
//   return newResponse
// }
// }

// export const mapResponse = (response, tabs = [], formState) => {
//   debugger
//   let newResponse = {...response }
//   if (newResponse) {
//     const tempResponse = Object.keys(newResponse).reduce((acc, next) => {
//       acc[next.toLowerCase()] = newResponse[next]
//       return acc
//     }, {})
//     const tabComponents = getIn(formState, 'masterOptions.tabComponents')
//     const secondaryTab = getIn(tabComponents, `${tabs[0]}-${tabs[1]}`)
//     if (secondaryTab && !getIn(secondaryTab, 'access')) {
//       tabs = tabs.slice(0, 1)
//     }
//     tabs.forEach(tab => {
//       debugger
//       // const t = toCamelCase(tab)
//       const t = toCamelCase(tab).toLowerCase()
//       const selectedTabData = tempResponse[t]
//       // delete tempResponse[t]
//       if (isObjectLike(selectedTabData) && !Array.isArray(selectedTabData)) {
//         debugger
//         delete newResponse[t]
//         newResponse = {
//           ...newResponse,
//           ...selectedTabData
//         }
//       }
//     })
//   }
//   debugger
//   newResponse.groupNames = tabs
//   return newResponse
// }

export const mapResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  // NEEDS update for groupNames?
  if (response) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      acc[next.toLowerCase()] = response[next]
      return acc
    }, {})
    const tabComponents = getIn(formState, 'masterOptions.tabComponents')
    const secondaryTab = getIn(tabComponents, `${tabIds[0]}-${tabIds[1]}`)
    if (secondaryTab && !getIn(secondaryTab, 'access')) {
      tabIds = tabIds.slice(0, 1)
    }
    tabIds.forEach(tab => {
      // const t = toCamelCase(tab)
      const t = toCamelCase(tab).toLowerCase()
      const selectedTabData = tempResponse[t]
      delete tempResponse[t]
      if (selectedTabData) {
        response = {
          ...response,
          ...selectedTabData
        }
      }
    })
  }
  response.groupNames = tabIds
  return response
}
export const trimmedCameled = compose(
  toCamelCase,
  trimNonWord
)
// export const isDisabled = (ownProps = {}, formState = {}) => {
//   let disabled = false
//   if (ownProps.disabled) {
//     if (typeof ownProps.disabled === 'boolean') {
//       disabled = ownProps.disabled
//     } else if (typeof ownProps.disabled === 'function') {
//       disabled = ownProps.disabled(formState)
//     } else if (Array.isArray(ownProps.disabled)) {
//       disabled = ownProps.disabled.every(fn => fn(formState))
//     }
//   }
//   return disabled
// }

// export const isDisabled = (ownProps = {}, field = {}, formState = {}) => {
//   const hasRecord = !!getIn(formState, 'hasRecord')
//   const isMaster = !!getIn(formState, 'masterOptions')
//   const isEditing = !!getIn(formState, 'isEditing')
//   const { preventAutoDisable } = ownProps
//   let disabled = false
//   // if (hasRecord && isEditing) {
//   //   return false
//   // }
//   if (isMaster && hasRecord && (!isEditing || !ownProps.preventAutoDisable)) {
//     return true
//   }
//   // if (ownProps.preventAutoDisable) return true
//   // // if(!isMaster) return
//   // // const ret = !(isMaster && !hasRecord && !ownProps.preventAutoDisable)

//   // // console.log(isMaster, hasRecord, ownProps.preventAutoDisable, ret)
//   // return isMaster && !hasRecord

//   if (field.disabled) {
//     return field.disabled
//   }
//   if (ownProps.disabled) {
//     if (typeof ownProps.disabled === 'boolean') {
//       disabled = ownProps.disabled
//     } else if (typeof ownProps.disabled === 'function') {
//       disabled = ownProps.disabled(field, formState)
//     } else if (Array.isArray(ownProps.disabled)) {
//       disabled = ownProps.disabled.every(fn => fn(field, formState))
//     }
//   }
//   return disabled
// }

export const isDisabled = (
  ownProps = {},
  field = {},
  formState = fromJS({})
) => {
  const hasRecord = !!getIn(formState, 'hasRecord')
  const isMaster = !!getIn(formState, 'masterOptions')
  const isEditing = !!getIn(formState, 'isEditing')
  const isLeader = field.leader
  const { preventAutoDisable } = ownProps
  let disabled = false
  // if (isLeader) return false
  if (isMaster) {
    // logic
    if ((!hasRecord || !isEditing) && !preventAutoDisable) {
      disabled = true // ?
    }
  }
  if (field.disabled) {
    disabled = true
  }
  if (ownProps.disabled) {
    if (is.bool(ownProps.disabled)) {
      disabled = ownProps.disabled
    } else if (is.fn(ownProps.disabled)) {
      disabled = ownProps.disabled(field, formState.toJS())
    } else if (is.array(ownProps.disabled)) {
      disabled = ownProps.disabled.some(fn => fn(field, formState.toJS()))
    }
  }
  return disabled
}

// export function validateField(field, formState, ownProps) {
//   let meta = getIn(formState, 'meta')
//   meta = ownProps.meta ? ownProps.meta : meta
//   meta = meta || {}
//   let errorText = null
//   let disabled = isDisabled(ownProps, field, formState)
//   if (meta.disabled) {
//     disabled = !!meta.disabled
//   }
//   if (!disabled && ownProps.validate) {
//     const val = getIn(field, 'value')
//     errorText =
//       typeof ownProps.validate === 'function'
//         ? ownProps.validate(val, formState)
//         : ownProps.validate.reduce((acc, next, index, arr) => {
//           const err = next(val, formState)
//           if (err) {
//             acc = acc.concat(`${err}`)
//             if (index !== arr.length - 1) {
//               acc = acc.concat('\n')
//             }
//           }
//           return acc
//         }, '')
//   }
//   return errorText
// }

export function validateField(field, formState, ownProps) {
  const validate = ownProps.validate || getIn(field, 'validate')
  let meta = getIn(formState, 'meta')
  meta = ownProps.meta ? ownProps.meta : meta
  meta = meta || {}
  let errorText = null
  let disabled = isDisabled(ownProps, field, formState)
  if (meta.disabled) {
    disabled = !!meta.disabled
  }
  if (!disabled && validate) {
    const val = getIn(field, 'value')
    // console.log('ownProps', ownProps)
    /*
      added the field data as a param to the validate
      callback for cases where error message text is
      provided by the API SVE 8/12/19
    */
    errorText = is.fn(validate)
      ? validate(val, formState, field)
      : validate.reduce((acc, next, index, arr) => {
          const err = next(val, formState, field)
          if (err) {
            acc = acc.concat(`${err}`)
            if (index !== arr.length - 1) {
              acc = acc.concat('\n')
            }
          }
          return acc
        }, '')
  }
  return errorText
}

export function disableMasterFieldsIfNoRecord(formState, ownProps) {
  const hasRecord = !!getIn(formState, 'hasRecord')
  const isMaster = !!getIn(formState, 'masterOptions')
  if (ownProps.preventAutoDisable) return true
  // if(!isMaster) return
  // const ret = !(isMaster && !hasRecord && !ownProps.preventAutoDisable)

  // console.log(isMaster, hasRecord, ownProps.preventAutoDisable, ret)
  return isMaster && !hasRecord
}

function _onDoubleClick(formState, ownProps) {
  const { form } = ownProps
  let { disableDoubleClickUnlock } = ownProps
  const isMaster = !!getIn(formState, 'masterOptions')

  const localDoubleClick = ownProps.onDoubleClick
  // disableDoubleClickUnlock =
  // if(isMaster && typeof getIn(formState, 'masterOptions.disableDoubleClickUnlock') === 'boolean'
  if (isMaster && !is.bool(disableDoubleClickUnlock)) {
    disableDoubleClickUnlock = getIn(
      formState,
      'masterOptions.disableDoubleClickUnlock'
    )
  }
  if (isMaster && !disableDoubleClickUnlock) {
    ownProps.dispatch(
      onDoubleClickAction(ownProps.propertyName, {
        form,
        master: true
      })
    )
    if (localDoubleClick && typeof localDoubleClick === 'function') {
      localDoubleClick()
    }
  }
  return localDoubleClick || noop
}

export const onDoubleClick = debounce(_onDoubleClick, 300)
export function makeWrapper() {}

export const resetFields = (state, ignoreResetFields = Set()) => {
  const fields = state.get('fields')
  const keys = Object.keys(fields.toJS())
  ignoreResetFields = ignoreResetFields.toJS()
  const newFields = keys.reduce((acc, next) => {
    let f = getIn(state, `fields.${next}`).toJS()
    if (ignoreResetFields.includes(next)) {
      acc = acc.set(next, fromJS(f))
      return acc
    }
    if (typeof f.value === 'boolean') {
      f.value = false
    } else if (getIn(f, 'grid')) {
      f = {
        ...f,
        rowData: [],
        columnDefs: []
      }
    } else if (!getIn(f, 'leader')) {
      f = {
        ...f,
        value: '',
        isFetching: false
      }
    }
    acc = acc.set(next, fromJS(f))
    if (getIn(acc, `${next}.tooltip`)) {
      acc = acc.set(next, fromJS({ tooltip: '' }))
    }
    return acc
  }, empty)
  return newFields
}

export const resetState = (state, ignoreResetFields) => {
  console.log(ignoreResetFields)
  let result = state
  // console.log(result.toJS())
  result = setIn(result, 'fields', resetFields(result, ignoreResetFields))
  result = setIn(result, 'values', empty)
  result = setIn(result, 'isFound', false)

  // result = setIn(result)
  if (getIn(result, 'masterOptions')) {
    result = setIn(result, 'hasRecord', false)
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'masterOptions.trackedTabs', Set())
  }
  // result = setIn(result, 'isEntitySuccessComplete', false)
  result = setIn(result, 'lastEntityId', getIn(result, 'dataId'))
  result = setIn(result, 'dataId', null)
  const oTitle = getIn(result, 'originalTitle')
  result = setIn(result, 'displayName', result.get('title'))
  result = setIn(result, 'title', oTitle)
  result = deleteIn(result, 'newEntity')
  return result
}

export function mapValuesOverFields(state, values) {
  const fields = getIn(state, 'fields') || fromJS({})
  const newFields = fields.map((field, key) => {
    const newVal = getIn(values, key)
    // check if it's a grid...
    if (!is.undefined(newVal)) {
      if (field.has('grid') && field.get('grid') === true) {
        if (!is.null(newVal)) {
          field = setIn(field, 'rowData', fromJS(newVal))
        } else {
          field = setIn(field, 'rowData', fromJS([]))
        }
      } else {
        field = setIn(field, 'value', newVal)
      }
    }
    if (field.has('errorText')) {
      field = deleteIn(field, 'errorText')
    }
    return field
  })
  return newFields
}

export const changePrimaryTab = (state, tab) => {
  let result = state
  tab = trimmedCameled(tab)
  let masterOptions = getIn(result, 'masterOptions')
  if (masterOptions) {
    masterOptions = setIn(masterOptions, 'selectedPrimaryTab', tab)
    const tabMap = getIn(masterOptions, 'secondaryTabMap')
    const tabs = getIn(tabMap, tab) || emptyList
    masterOptions = setIn(
      masterOptions,
      'selectedSecondaryTab',
      trimmedCameled(tabs.first())
    )
    result = setIn(result, 'masterOptions', masterOptions)
  }

  return result
}

export const changeFormTab = (
  state,
  selectedPrimaryTab,
  selectedSecondaryTab
) => {
  let result = state
  // debugger
  let masterOptions = getIn(result, 'masterOptions')
  if (masterOptions) {
    masterOptions = setIn(
      masterOptions,
      'selectedPrimaryTab',
      trimmedCameled(selectedPrimaryTab)
    )
    const tabMap = getIn(masterOptions, 'secondaryTabMap')
    const tabs = getIn(tabMap, selectedPrimaryTab) || emptyList
    masterOptions = setIn(
      masterOptions,
      'selectedSecondaryTab',
      selectedSecondaryTab || trimmedCameled(tabs.first())
    )
    let tabComponents = getIn(masterOptions, 'tabComponents')
    let tab = selectedSecondaryTab // getIn(tabComponents, getIn(masterOptions, 'selectedSecondaryTab'))
      ? getIn(
          tabComponents,
          `${getIn(masterOptions, 'selectedPrimaryTab')}-${getIn(
            masterOptions,
            'selectedSecondaryTab'
          )}`
        )
      : getIn(tabComponents, getIn(masterOptions, 'selectedPrimaryTab'))

    // console.log(tab)
    // debugger
    if (getIn(tab, 'access')) {
      tab = setIn(tab, 'accessed', true)
      if (selectedSecondaryTab) {
        tabComponents = setIn(
          tabComponents,
          `${getIn(masterOptions, 'selectedPrimaryTab')}-${getIn(
            masterOptions,
            'selectedSecondaryTab'
          )}`,
          tab
        )
      } else {
        tabComponents = setIn(
          tabComponents,
          `${getIn(masterOptions, 'selectedPrimaryTab')}`,
          tab
        )
      }
      masterOptions = setIn(masterOptions, 'tabComponents', tabComponents)
    }
    let trackedTabs = getIn(masterOptions, 'trackedTabs')
    trackedTabs = trackedTabs.add(selectedPrimaryTab)
    if (selectedSecondaryTab) {
      trackedTabs = trackedTabs.add(selectedSecondaryTab)
    }
    masterOptions = setIn(masterOptions, 'trackedTabs', trackedTabs)
    result = setIn(result, 'masterOptions', masterOptions)
  }
  return result
}

export const getFormSelector = form => state => getIn(state, `ddiForm.${form}`)
export function onChangeNumeric(e) {
  if (e.target.value != null) {
    this.setState(
      {
        value: e.target.value,
        dirty: e.target.value !== this.props.value
      },
      () => {
        if (
          this.props.onChangeCb &&
          typeof this.props.onChangeCb === 'function'
        ) {
          this.props.onChangeCb(e)
        }
      }
    )
  }
}

export function onBlurNumeric(e) {
  const { value } = e.target
  if (this.state.dirty) {
    this.setState(
      {
        value,
        dirty: false
      },
      () => this.props.onBlur(value)
    )
  }
}

export function onChange(e) {
  if (e && e.persist) {
    e.persist()
  }

  this.setState(
    {
      value: e.target.value,
      dirty: this.props.value !== e.target.value
    },
    () => {
      if (
        this.props.onChangeCb &&
        typeof this.props.onChangeCb === 'function'
      ) {
        this.props.onChangeCb(e)
      }
    }
  )
}

export function onBlur(e) {
  const { value } = e.target
  if (this.state.dirty) {
    this.props.onBlur(value)
    this.setState({
      value,
      dirty: false
    })
  }
}

export const filterHidden = formState => (tab = {}) => {
  formState = formState.toJS ? formState.toJS() : formState
  const isHidden = getIn(tab, 'isHidden')

  if (typeof isHidden === 'undefined') {
    return true
  }
  if (typeof isHidden === 'function') {
    const ret = !isHidden(formState)
    return ret
  }

  return !!isHidden
}

export const updateValuesAndFields = (state, values) => {
  values = values.toJS ? values : fromJS(values)
  let result = state
  const oldValues = getIn(result, 'values')

  const newValues = values.reduce(
    // (acc, next, key) => (next ? (acc = setIn(acc, key, next)) : acc),
    (acc, next, key) => (acc = setIn(acc, key, next)),
    oldValues
  )
  result = setIn(result, 'values', newValues)
  const fields = mapValuesOverFields(result, newValues)
  result = setIn(result, 'fields', fields)
  return result
}
export const bindSagaWithRest = (...args) => saga =>
  function* wrappedSaga(form, ...rest) {
    yield fork(saga, form, ...args, ...rest)
  }

export const createTab = tab => ({
  component: tab.component,
  isHidden: tab.isHidden,
  access: tab.access,
  isDisabled: tab.isDisabled,
  callAlways: tab.callAlways,
  mapResponse: tab.mapResponse
})
export const createMasterOptions = (masterOptions = {}) => {
  const options = {
    ...masterOptions,
    ...masterOptions.tabs.reduce(
      (acc, tab, i) => {
        // does tab have a component or it's own tabs?
        // if compoennt add it to the tabComponents map
        // if tabs loop throuhg the tabs and add to tabCOmponets map tabtitle.tabtitle

        if (
          (masterOptions.selectedPrimaryTab &&
            !masterOptions.selectedSecondaryTab &&
            trimmedCameled(tab.title) === masterOptions.selectedPrimaryTab) ||
          (!masterOptions.selectedPrimaryTab &&
            !masterOptions.selectedSecondaryTab &&
            i === 0)
        ) {
          acc.selectedPrimaryTab = trimmedCameled(tab.title)
          if (tab.tabs && tab.tabs.length) {
            acc.selectedSecondaryTab = Array.isArray(tab.tabs)
              ? trimmedCameled(tab.tabs[0].title)
              : null
          } else {
            acc.selectedSecondaryTab = null
          }
        }
        // debugger
        // const access = is.fn(tab.access) ? tab.access.bind(action.meta.form) : tab.access
        acc.defaultPrimaryTabOrder.push({
          access: tab.access,
          icon: tab.icon,
          title: tab.title,
          isHidden: tab.isHidden,
          isDisabled: tab.isDisabled,
          callAlways: tab.callAlways
        })
        acc.primaryTabOrder.push({
          access: tab.access,
          icon: tab.icon,
          title: tab.title,
          isHidden: tab.isHidden,
          isDisabled: tab.isDisabled,
          callAlways: tab.callAlways
        })
        // debugger
        // if (tab.component) {
        acc.tabComponents[trimmedCameled(tab.title)] = createTab(tab)
        if (tab.tabs) {
          acc.defaultSecondaryTabMap[trimmedCameled(tab.title)] = []
          tab.tabs.forEach(t => {
            // acc.tabComponents[`${tab.title}-${t.title}`] = t.component
            acc.tabComponents[
              `${trimmedCameled(tab.title)}-${trimmedCameled(t.title)}`
            ] = createTab(t)
            acc.defaultSecondaryTabMap[trimmedCameled(tab.title)].push(t.title)
          })
        }
        return acc
      },
      {
        defaultPrimaryTabOrder: [],
        defaultSecondaryTabMap: {},
        primaryTabOrder: [],
        // secondaryTabOrder: [],
        selectedPrimaryTab: '',
        selectedSecondaryTab: '',
        tabComponents: {},
        trackedTabs: Set()
      }
    )
  }
  // debugger
  return options
}

export const extractIndexSearchFieldsFromMeta = (meta = {}) => {
  // debugger
  let indexSearchFields = meta.indexSearchFields || meta.searchFields

  indexSearchFields = indexSearchFields
    ? indexSearchFields.reduce((acc, next) => {
        //  acc[next.searchType] = next
        acc[next.fieldName] = next
        return acc
      }, {})
    : {}
  return {
    ...meta,
    ...indexSearchFields
  }
}
// keys either a string or object containing name and renamed fields...
// this needs to go.. be
export const mergeMetaWithContainer = ({
  state,
  payload,
  keys = [
    {
      ignored: [],
      name: 'searchFields',
      rename: { selectedWarehouseId: 'defaultSelectedWarehouseId' }
    } /* ,'searchFields' */
  ],
  ignored = []
}) => {
  // debugger
  let result = state
  let meta = getIn(result, 'meta')

  const filterKeys = x => {
    if (is.string(x)) {
      return !ignored.includes(x)
    }
    if (x && x.fieldName) {
      return !ignored.includes(x.name)
    }
    return false
  }
  keys.filter(filterKeys).forEach(key => {
    // debugger
    let data
    if (is.string(key)) {
      data = payload[key]
      if (data && is.array(data)) {
        data = data.reduce((acc, next) => {
          if (next && !ignored.includes(next.fieldName)) {
            acc[next.fieldName] = next
          }
          return acc
        }, {})
      }
    } else if (key && key.fieldName) {
      data = payload[key.fieldName]
      if (data && is.array(data)) {
        data = data.reduce((acc, next) => {
          if (next && !key.ignored.includes(next.fieldName)) {
            acc[key.ignored[next.fieldName] || next.fieldName] = next
          }
          return acc
        }, {})
      }
    }
    meta = meta.mergeDeep(fromJS(data))
  })

  result = setIn(result, 'meta', meta)
  return result
}

export const validateComponentProp = (props, propName, componentName) => {
  if (!isValidElementType(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`.`
    )
  }
  return null
}

export function isValidDate(date) {
  return date && !isNaN(Date.parse(date))
}

export function getMapResponse({ formState, tabIds = [] }) {
  let defaultMapResponse = mapResponse
  // yield (console.log(formState, tabIds))
  const masterOptions = getIn(formState, 'masterOptions')
  let tabId
  if (tabIds.length) {
    if (tabIds.length === 1) {
      tabId = tabIds[0]
    } else if (tabIds.length === 2) {
      tabId = `${tabIds[0]}-${tabIds[1]}`
    }
  } else {
    const selectedPrimaryTab = getIn(masterOptions, 'selectedPrimaryTab')
    const selectedSecondaryTab = getIn(masterOptions, 'selectedSecondaryTab')
    tabId = selectedSecondaryTab
      ? `${selectedPrimaryTab}-${selectedSecondaryTab}`
      : selectedPrimaryTab
  }
  if (tabId) {
    const tabComponents = getIn(masterOptions, 'tabComponents')
    const tab = getIn(tabComponents, tabId)
    const tMapResponse = getIn(tab, 'mapResponse')
    const formMapResponse = getIn(masterOptions, 'mapResponse')
    if (tMapResponse) {
      defaultMapResponse = tMapResponse
    } else if (formMapResponse) {
      defaultMapResponse = formMapResponse
    }
  }

  return defaultMapResponse
}
