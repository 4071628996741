import React, { useEffect, useRef } from 'react'
import {
  toCamelCase,
  is,
  getRgb,
  setIn,
  getIn,
  deleteIn,
  empty,
  emptyList
} from 'utils'
import { fromJS } from 'immutable'
import { withStyles } from '@material-ui/core/styles'
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Tab
} from '@material-ui/core'
import { getSelectedTabsFromState } from 'ddiForm/utils'

const primaryTabs = ['header', 'detail', 'final', 'shipments', 'audit']

export const googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query='
export function getSelectedRowLineNumberFromFormState(formState) {
  if (formState) {
    const lineItemsField = getIn(formState, 'fields.lineItems') || fromJS({})
    const selectedRowIndex = getIn(lineItemsField, 'selectedRowIndex') || 0
    const rowData = getIn(lineItemsField, 'rowData') || fromJS([])
    const row = rowData.get(selectedRowIndex)
    const lineNumber = (row && row.get('lineNumber')) || 1
    //
    return lineNumber
  }
  return null
}
export const mapGetRecordResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      acc[next.toLowerCase()] = response[next]
      return acc
    }, {})

    tabIds.forEach(tab => {
      const t = toCamelCase(tab).toLowerCase()
      const selectedTabData = tempResponse.record
        ? tempResponse.record[t]
        : tempResponse[t]
      if (tempResponse.record) {
        delete tempResponse.record[t]
      }
      // delete tempResponse.record
      if (selectedTabData) {
        res = {
          ...response,
          ...response.record,
          ...selectedTabData
        }
      }
    })
  }

  return res
}

export const getGoogleMapsURL = (address1, address2, cityStateZip) => {
  let href = '#'
  if (address1) {
    let addressStr = `${address1} ${address2}, ${cityStateZip}`
    addressStr = addressStr.replace(',', '')
    href = `${googleMapsUrl}${encodeURI(addressStr)}`
  }
  return href
}

export function renderShipToAddress(
  shipToAddress,
  phone,
  customerId,
  email,
  fax
) {
  if (customerId && shipToAddress && shipToAddress.address1) {
    const { address1, address2, address3, cityStateZip } = shipToAddress
    return (
      <div style={{ padding: 0 }}>
        <ul
          className="list-plain"
          style={{ listStyleType: 'none', paddingLeft: 0, marginLeft: 0 }}
        >
          <li>
            <a
              href={getGoogleMapsURL(address1, address2, cityStateZip)}
              rel="noopener noreferrer"
              target="_blank"
            >
              {address1}
            </a>
          </li>
          {address2 ? <li>{address2}</li> : null}
          {address3 ? <li>{address3}</li> : null}
          <li>{cityStateZip}</li>
          {phone && <li>Phone: {phone}</li>}
          {fax && <li>Fax: {fax}</li>}
          {email && <li>Email: {email}</li>}
        </ul>
      </div>
    )
  }

  return null
}

export const validateSearchField = (val, formState, field) => {
  if (field && field.errorMessage) {
    return field.errorMessage
  }

  return null
}

export const mapLinePropertyChangeResponse = (response, groupNames = []) => {
  let res
  if (response && response.record && Object.keys(response.record).length) {
    const tempResponse = Object.keys(response.record).reduce((acc, next) => {
      acc[next.toLowerCase()] = response.record[next]
      return acc
    }, {})

    groupNames.forEach(tab => {
      const t = toCamelCase(tab).toLowerCase()
      const selectedTabData = tempResponse[t]
      delete tempResponse[t]
      if (selectedTabData) {
        res = {
          ...response,
          record: {
            ...response.record,
            ...selectedTabData
          }
        }
      }
    })
  }

  return res
}

export const extractString = val => {
  if (is.string(val)) {
    return val.replace(/[^a-z]/gi, '')
  }

  return ''
}

export const isValidQuantityInput = val => {
  // accepts:
  // numbers, alphanumeric with 1 letter at end (b or c)
  if (is.number(Number(val)) || is.number(val)) {
    return true
  }

  if (is.string(val)) {
    // if only has 1 alphabet character and it is last index
    const lastChar = val[val.length - 1]
    const isValidLastChar = lastChar != null && isNaN(lastChar)

    if (extractString(val).length !== 1) {
      return false
    }
    return isValidLastChar
  }

  return false
}

export const calculateResolvedQuantity = (
  val,
  key,
  box = null,
  carton = null
) => {
  const num = is.string(val) ? parseFloat(val.replace(/[^\d.-]/g, '')) : val

  if (key && key.toLowerCase() === 'b' && box != null && is.number(num)) {
    return num * box
  }

  if (key && key.toLowerCase() === 'c' && carton != null && is.number(num)) {
    return num * carton
  }

  return is.number(num) ? num : 0
}

// export const roundToNextMultiple = (val, num) => Math.ceil(val / num) * num
export const roundToNextMultiple = (val, num) => {
  if (is.number(val) && is.number(num)) {
    return Number(Number.parseFloat(Math.ceil(val / num) * num).toFixed(1))
  }

  return 0
}

export const removeSuppressedReference = (lineItems, lineNumber) => {
  if (is.array(lineItems) && lineItems.length) {
    return lineItems.reduce((acc, next) => {
      if (next !== lineNumber) {
        const num = next > lineNumber ? next - 1 : next
        acc.push(num)
      }
      return acc
    }, [])
  }

  return null
}

export function generateCellStyle(val, isOverMax = false) {
  const cellStyle = { fontWeight: 'bold' }
  if ((val && val <= 0) || isOverMax) {
    return {
      ...cellStyle,
      color: '#FF0000'
    }
  }

  return cellStyle
}

export function renderStatusIcon(color) {
  const red = getRgb({ r: 255, g: 0, b: 0 })
  const green = getRgb({ r: 0, g: 128, b: 0 })
  const brown = getRgb({ r: 139, g: 0, b: 0 })
  const purple = getRgb({ r: 128, g: 0, b: 128 })

  const statusMap = {
    [red]: 'error_outline',
    [green]: 'flag',
    [brown]: 'notifications',
    [purple]: 'comment'
  }

  if (statusMap[color]) {
    return statusMap[color]
  }

  return 'error_outline'
}

export const mapGetInvoicingTabResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      acc[next.toLowerCase()] = response[next]
      return acc
    }, {})

    tabIds.forEach(tab => {
      const t = toCamelCase(tab).toLowerCase()
      const selectedTabData = tempResponse.record[t]
      delete tempResponse.record[t]
      // delete tempResponse.record
      if (selectedTabData) {
        res = {
          ...response,
          ...response.record,
          ...selectedTabData
        }
      }
    })

    res = res.final
      ? {
          ...res,
          ...res.final
        }
      : res
  }

  return res
}

/* START compacted expansion panels for Sales Order interface */
export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 30,
    padding: '0 14px',
    '&$expanded': {
      minHeight: 30
    }
  },
  content: {
    margin: '5px 0',
    '&$expanded': {
      margin: '5px 0'
    }
  },
  expandIcon: {
    padding: 0
  },
  expanded: {}
})(MuiExpansionPanelSummary)

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 10
  }
}))(MuiExpansionPanelDetails)
/* END compacted expansion panels for Sales Order interface */

export const generateTabs = (tabs = [], maxWidth = 264, maxCharLength = 10) =>
  tabs.map((item, index) => {
    if (item) {
      if (item.length > maxCharLength) {
        return <Tab value={item} label={item} style={{ maxWidth }} key={item} />
      }
      return <Tab value={item} label={item} key={item} />
    }

    return null
  })

export const setGridHeight = (
  array = [],
  additionalHeight = 0,
  headerHeight = 32
) => array.length * 28 + (headerHeight + array.length) + additionalHeight

export const setGridHeightImmutable = (
  list = emptyList,
  additionalHeight = 0,
  headerHeight = 32
) => list.size * 28 + (headerHeight + list.size) + additionalHeight

export const handleCopyOrderData = (result, payload) => {
  for (const prop in payload) {
    if (Array.isArray(payload[prop])) {
      result = setIn(
        result,
        `fields.copyProducts.${prop}.rowData`,
        fromJS(payload[prop])
      )
    } else {
      result = setIn(
        result,
        `fields.copyProducts.${prop}.value`,
        fromJS(payload[prop])
      )
    }
  }

  return result
}

/* used in both copyOrderSagas & detailSagas */
export const getDuplicateProductMessage = data =>
  data.dataId && data.description && data.lineNumber
    ? `${data.dataId} ${data.description} already found on line ${data.lineNumber}. Continue?`
    : 'This item already exists. Continue?'

export const getGroupNames = formState => {
  let groupNames = getSelectedTabsFromState(formState)

  // refactor once Final/Checkout tab finalized
  // LL 12/23/19

  if (groupNames.includes('shipments') || groupNames.includes('checkout')) {
    groupNames = [...groupNames, 'signature', 'final']
  } else if (groupNames.includes('activities')) {
    groupNames = ['activities']
  } else if (groupNames.includes('audit')) {
    groupNames = ['audit']
  } else {
    groupNames = ['detail', 'header', 'final']
  }

  return groupNames
}

export const handleFastProductData = (
  state,
  data,
  storeValuesOnly = ['warnCommas', 'patterns', 'patternType'],
  ignoreData = ['dataId'],
  setDescriptions = true
) => {
  for (const prop in data) {
    if (storeValuesOnly.includes(prop)) {
      state = setIn(state, `values.fastProduct.${prop}`, fromJS(data[prop]))
    } else if (!ignoreData.includes(prop)) {
      /* make up for empty value as default statusType */
      const value = prop === 'statusType' && !data[prop] ? 'A' : data[prop]
      state = setIn(state, `fields.fastProduct.${prop}.value`, fromJS(value))
      state = setIn(state, `values.fastProduct.${prop}`, fromJS(value))

      const descriptionMap = {
        buylineId: 'buylineDescription',
        hazMatId: 'hazMatDescription',
        priceGroupId: 'priceGroupDescription',
        primaryVendorId: 'primaryVendorDescription',
        productLineId: 'productLineDescription',
        uomForDisplayId: 'uomForDisplayDescription'
      }

      if (descriptionMap[prop] && setDescriptions) {
        const descriptionKey = descriptionMap[prop]
        state = setIn(
          state,
          `fields.fastProduct.${prop}.description`,
          fromJS(data[descriptionKey] ? data[descriptionKey] : '')
        )
      }
    } else if (ignoreData.includes(prop) && prop === 'dataId') {
      state = setIn(state, 'meta.fastProduct.key', data[prop])
    }
  }

  return state
}

// export const getSidebarRowData = (form, key, defaultDataSet = []) => {
//   const lineItems = form.fields && form.fields.lineItems
//   const rowData = lineItems && lineItems.rowData ? lineItems.rowData : []
//   const index = lineItems && lineItems.selectedRowIndex
//   if (index === null || index === undefined) return []
//   const lineItem = rowData[index]
//   if (lineItem && lineItem[key]) {
//     return lineItem[key]
//   }
//   return defaultDataSet
// }

export const getSidebarRowData = (form, key, defaultDataSet = emptyList) => {
  const lineItems = getIn(form, 'fields.lineItems.rowData')
  const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')

  if (is.number(selectedRowIndex) && lineItems && lineItems?.get) {
    const selectedLineItem = lineItems.get(selectedRowIndex)
    if (selectedLineItem && selectedLineItem?.get) {
      return selectedLineItem.get(key) || defaultDataSet
    }
  }

  return defaultDataSet
}

export const resizeGrid = api => {
  if (api && api.getAllColumns && api.autoSizeColumns) {
    const allColumnIds = []
    const columns = api.getAllColumns()
    // console.log('columns', columns)
    if (!columns) {
      return
    }

    columns.forEach(column => {
      allColumnIds.push(column.colId)
    })
    api.autoSizeColumns(allColumnIds)
  }
}

// export const shouldPromptAdvancedCell = (column, rowIndex, colIdsToTrack) => {
//   const { colId } = column
//   const node = column.gridApi.getDisplayedRowAtIndex(rowIndex)

//   if (colIdsToTrack.includes(colId)) {
//     if (
//       (colId === 'quantityChange' && node.data.promptForQuantity) ||
//       (colId === 'pricing' && node.data.promptForPrice)
//     ) {
//       return true
//     }
//   } else {
//     return true
//   }
//   return false
// }
export const shouldPromptAdvancedCell = (column, rowIndex, colIdsToTrack) => {
  const { colId } = column
  const node = column.gridApi.getDisplayedRowAtIndex(rowIndex)

  if (colIdsToTrack.includes(colId)) {
    if (
      (colId === 'quantityChange' && node.data.promptForQuantity) ||
      (colId === 'pricing' && node.data.promptForPrice)
    ) {
      return true
    }
  }
  return false
}

export const updateGroups = (state = {}, groups = {}) => {
  let result = state

  if (groups && Object.keys(groups).length) {
    for (const prop in groups) {
      if (getIn(result, `fields.groups.${prop}.grid`) && groups[prop]) {
        const rowData = getIn(result, `fields.groups.${prop}.rowData`)
        const newGrid = groups[prop]
        let mergedData = rowData && rowData.toJS ? rowData.toJS() : newGrid

        if (mergedData.length !== newGrid.length) {
          if (mergedData.length > newGrid.length) {
            mergedData = mergedData
              .filter(row => {
                const { dataId } = row

                return (
                  newGrid.some(r => {
                    return r.dataId === dataId
                  }) || row.rowId === 'blankrow'
                )
              })
              .map((row, i) => {
                return {
                  ...row,
                  ...newGrid[i]
                }
              })
          } else {
            mergedData = fromJS(mergedData)
              .mergeDeep(fromJS(groups[prop]))
              .toJS()
              .map(x => {
                x = { ...x, rowId: x.dataId }
                return x
              })
          }
        } else {
          mergedData = mergedData.map((x, i) => {
            x = {
              ...x,
              ...newGrid[i]
            }
            if (x.rowId === 'blankrow') {
              x.rowId = x.dataId
            }
            return x
          })
        }

        result = setIn(
          result,
          `fields.groups.${prop}.rowData`,
          fromJS(mergedData)
        )
      } else {
        result = setIn(
          result,
          `fields.groups.${prop}.value`,
          fromJS(groups[prop])
        )
      }
    }
  }

  return result
}

export const getAdditionalDataMapContent = (form, key, defaultValue = []) => {
  const { additionalDataMap = {} } = form
  const lineItems = form.fields && form.fields.lineItems
  const rowData = lineItems && lineItems.rowData ? lineItems.rowData : []
  const index = lineItems && lineItems.selectedRowIndex
  if (index === null || index === undefined) return defaultValue
  const lineItem = rowData[index]
  if (lineItem && lineItem.rowId && additionalDataMap[lineItem.rowId]) {
    return additionalDataMap[lineItem.rowId][key] || defaultValue
  }
  return defaultValue
}

export const getAdditionalDataMapContentFromImmutable = (
  rowData = [],
  additionalDataMap = {},
  selectedRowIndex,
  key,
  defaultValue = []
) => {
  if (
    selectedRowIndex === null ||
    selectedRowIndex === undefined ||
    !rowData.length
  )
    return defaultValue
  const lineItem = rowData[selectedRowIndex]
  if (lineItem && lineItem.rowId && additionalDataMap[lineItem.rowId]) {
    return additionalDataMap[lineItem.rowId][key] || defaultValue
  }
  return defaultValue
}

export const getAdditionalDataFromContext = (
  form,
  key,
  defaultValue = emptyList
) => {
  const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')
  const lineItems = getIn(form, 'fields.lineItems.rowData') || emptyList
  const additionalDataMap = getIn(form, 'additionalDataMap') || empty

  if (
    !is.number(selectedRowIndex) ||
    !lineItems.size ||
    !additionalDataMap.size
  ) {
    return defaultValue
  }

  const seletedLineItem =
    lineItems && lineItems?.get ? lineItems.get(selectedRowIndex) : empty

  const rowId =
    seletedLineItem && seletedLineItem?.get
      ? seletedLineItem.get('rowId')
      : 'blankrow'

  if (rowId === 'blankrow' || !rowId) {
    return defaultValue
  }

  const map =
    additionalDataMap && additionalDataMap?.get
      ? additionalDataMap.get(rowId)
      : empty

  if (map && map?.get) {
    return map.get(key) || defaultValue
  }

  return defaultValue
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const resetPaymentModalFlags = result => {
  if (getIn(result, 'overpaymentResponsePosting')) {
    result = deleteIn(result, 'overpaymentResponsePosting')
  }

  if (getIn(result, 'manualIdPosting')) {
    result = deleteIn(result, 'manualIdPosting')
  }

  if (getIn(result, 'cardProcessorInitialized')) {
    result = deleteIn(result, 'cardProcessorInitialized')
  }

  return result
}

export const emptyRow = {
  rowId: 'blankrow',
  lineNumber: '1',
  dataId: null,
  description: '',
  quantityOrdered: null,
  uomId: 'EA',
  netPrice: 0,
  comments: [],
  data: [],
  itemGroupId: null,
  itemGroupDescription: ''
}

export const getSelectedRowIndex = form => {
  const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')

  if (is.number(selectedRowIndex)) {
    return selectedRowIndex
  }

  return null
}

export const getLineItems = form =>
  getIn(form, 'fields.lineItems.rowData') || emptyList

export const tryParseJSON = str => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return null
  }
}

export const isGridLocalStorageRegistered = (str, formName) => {
  const obj = tryParseJSON(str)
  if (obj && typeof obj === 'object' && obj !== null && obj?.[formName]) {
    return true
  }

  return false
}

export const sortHiddenCols = colDefs =>
  colDefs.sort((a, b) => {
    if ((a.hide && b.hide) || (!a.hide && !b.hide)) {
      return 0
    }

    if (a.hide && !b.hide) {
      return 1
    }

    if (b.hide && !a.hide) {
      return -1
    }

    return 0
  })
